<template>
  <div class="content-wrap">
    <div class="banner-wrap">
      <swiper :options="swiperOptionv" ref="mySwiperv">
        <swiper-slide class="banner-box" >
          <swiper :options="swiperOptionh" ref="mySwiperh">
            <swiper-slide class="banner-box" v-for="(item,ind) in banners" :key="ind">
              <img class="banner-item" :src="baseURL + item.coverImage" alt="加载失败">
              <div class="banner-title">
                <div class="title-top">{{item.title}}</div>
                <p class="title-bottom">{{item.description}}</p>
                <div class="title-line"></div>
              </div>
              <div class="banner-text">
                <p>康存数科集团</p>
                <div class="banner-text-en">
                  <!--              KANGCUN<span></span>DIGTAL-->
                  <p><img src="@/assets/imgs/homepage/home-banner-text.png" alt=""></p>
                  <img src="@/assets/imgs/homepage/home-icon.png" alt="">
                </div>
                <div class="banner-line"></div>
                <div class="banner-ind">
                  <span>0{{ind+1}}</span> / 0{{banners.length}}
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-v" ref="paginationh" slot="pagination"></div>
          </swiper>
        </swiper-slide>
        <swiper-slide class="banner-box">
          <AboutKangCun :activeIndV="activeIndV"></AboutKangCun>
        </swiper-slide>
        <swiper-slide class="banner-box">
          <Business :activeIndV="activeIndV"></Business>
        </swiper-slide>
        <swiper-slide class="banner-box">
          <Cooperation :activeIndV="activeIndV"></Cooperation>
        </swiper-slide>
        <swiper-slide class="banner-box">
          <News :activeIndV="activeIndV"></News>
        </swiper-slide>
        <swiper-slide class="banner-box">
          <JoinUs :activeIndV="activeIndV"></JoinUs>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-h" ref="paginationv" slot="pagination"></div>
      </swiper>


    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
import { selectIndexMaterials } from '@/api/common.js'
import { baseURL } from '@/api/config.js'
import "swiper/css/swiper.css";
export default {
  name: 'HomePage',
  data(){
    return {
      baseURL: baseURL,
      banners:[
        // {img:require("@/assets/imgs/homepage/home-banner1.jpg")},
        // {img:require("@/assets/imgs/homepage/home-banner2.jpg")},
        // {img:require("@/assets/imgs/homepage/home-banner3.jpg")},
        // {img:require("@/assets/imgs/homepage/home-banner4.jpg")},
      ],
      swiperOptionv: {
        direction:'vertical',
        effect: "slide",
        fade:true,
        speed: 400,
        mousewheel: true,
        // API
        pagination: {
          el: '.swiper-pagination-h',
          clickable :true
        },
        // on:{
        //   slideChange: function(){
        //     alert('改变了，activeIndex为'+this.activeIndex);
        //   },
        // },
      },
      swiperOptionh: {
        effect: "fade",
        fade:true,
        speed: 1000,
        // API
        pagination: {
          el: '.swiper-pagination-v',
          clickable :true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
      },
      activeIndV:0,
      activeIndH:0
    }
  },
  // computed: {
  //   swiper () {
  //     return this.$refs.mySwiperv
  //   },
  //   // 提供一个可操作的swiper对象
  // },
  components: {
    Swiper,
    SwiperSlide,
    AboutKangCun: () => import( "@/views/AboutKangCun.vue"),
    Business: () => import( "@/views/Business.vue"),
    Cooperation: () => import( "@/views/Cooperation.vue"),
    News:() => import( "@/views/News.vue"),
    JoinUs: () => import( "@/views/JoinUs.vue"),
  },
  created() {
    this.getImags()
  },
  mounted(){
    let _this = this;
    // console.log(this.$refs.mySwiperv.$swiper)
    this.$refs.mySwiperv.$swiper.on('slideChange',function(){
      _this.activeIndV = this.activeIndex
    })

    // this.$refs.mySwiperh.$swiper.on('slideChange',function(){
    //   _this.activeIndH = this.activeIndex
    // })
  },
  methods:{
    getImags(){
      selectIndexMaterials({
        lang: sessionStorage.getItem("langType"),
        indexType: "banner",
      }).then((res)=>{
        if(res.rows.length){
          this.banners = res.rows;
        }
        console.log(res)
      })
    }
  }
}
</script>
<style scoped lang="scss">
.banner-wrap{
  width: 100%;
  height: 100%;
  .swiper-container{
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1440px) and (min-width: 1366px) {
      .banner-box::v-deep .router-content {
        //background-color:#ccc;
        padding-top: 200px;
        transform: scale(0.9,0.9);
        transform-origin: center top;
      }
    }

    @media screen and (max-width: 1366px) {
      .banner-box::v-deep .router-content {
        //background-color:#ccc;
        padding-top: 200px;
        transform: scale(0.75,0.75);
        transform-origin: center top;
      }
    }


    .banner-box{
      position: relative;
      width: 100%;
      height: 100%;



      .banner-item{
        position: absolute;
        top: calc(50% - 540px);
        left: calc(50% - 1000px);
      }
      .banner-title{
        position: absolute;
        width: 770px;
        height: 252px;
        background: rgba(4,70,161,0.5);
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
        text-shadow: 1.5px 2.598px 13px rgba(1, 90, 183, 1);
        text-align: center;
        line-height: 1.56;
        .title-top{
          font-size: 70px;
          margin-top: 40px;
        }
        .title-bottom{
          font-size: 25px;
          margin-bottom: 15px;
        }
        .title-line{
          height: 1px;
          margin: 0 90px;
          background: #fff;
        }
      }
      .banner-text{
        position: absolute;
        top: 165px;
        right: 100px;
        z-index: 50;
        color: #fff;
        &>p{
          font-size: 24px;
          width: 20px;
        }
        .banner-text-en{
          position: absolute;
          top: 5px;
          left: -44px;
          height: 400px;
          width: 20px;
          text-align: center;
          &>p{
            margin-bottom: 20px;
          }
        }
        .banner-line{
          position: absolute;
          top: 385px;
          left: -35px;
          width: 1px;
          height: 150px;
          background: #fff;
        }
        .banner-ind{
          position: absolute;
          top: 550px;
          left: -85px;
          width: 120px;
          display: flex;
          align-items: flex-start;
          line-height: 1;
          &>span{
            font-size: 60px;
            line-height: 50px;
          }
        }
      }
    }
    .swiper-pagination{
      display: flex;
      justify-content: center;
      align-items: center;

      &.swiper-pagination-v{
        bottom:40px;
      }
      &.swiper-pagination-h{
        flex-direction: column;
        right: 20px;
      }
      &.swiper-pagination-v::v-deep .swiper-pagination-bullet{
        margin: 0 25px;
        opacity:1;
        background: #ffffff;
      }
      &.swiper-pagination-h::v-deep .swiper-pagination-bullet{
        margin: 25px 0;
        opacity:1;
        background: #ffffff;
      }
      & ::v-deep .swiper-pagination-bullet-active{
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
